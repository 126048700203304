:root {
  --color-background: #252836;
  --color-background-dark: #1f1d2b;
  --color-background-darken: #12151f;
  --color-primary: #ea7c69;
  --color-primary-50: #ea7c6940;
  --color-secondary: 33, 150, 83;
  --color-input-background: #2d303e;
  --color-input-border: #393c49;
  --color-input-placeholder: #889898;
  --color-text-base: #fafafa;
  --color-text-in-background-dark: #abbbc2;
  --color-text-in-background-darken: #3b5162;
  --color-action-product: #797780;
  --color-danger: #eb5757;
  /* font-size: 60%; */
}


/* body {
  background: var(--color-background);
} */

.hidden {
  display: none;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}


body,
button {
  color: var(--color-text-base);
}

input {
  color: var(--color-text-base) !important;
}

.MuiFormControl-root label {
  margin-top: -15px;
}

/* label, input {
  transition: all 0.2s;
  touch-action: manipulation;
} */
/* input {
  font-size: 1em;
  cursor: text;
  border-bottom: 1px solid #ccc;
  font-family: inherit;
  -webkit-appearance: none;
  padding: 20px 10px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
  width: 100%;
  background: none;
  height: 3.5em;
  -webkit-tap-highlight-color: transparent;
  display: block;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
} */


/* input:focus {
  outline: 0;
  border: 1px solid var(--color-primary);
} */

/* label {
  letter-spacing: 0.05em;
  color: var(--color-text-base) !important;
} */

/* input:placeholder-shown + label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 2.125rem) scale(1.5);
} */


.activeMenu button {
  color: var(--color-primary);
}

.success {
  height: 48px;
  width: 100%;
  border-radius: 8px;
  background: rgb(var(--color-secundary));
  border: 1px solid rgb(var(--color-secundary));
  color: var(--color-text-base);
}

.success:active {
  box-shadow: 0 3px 24px rgba(var(--color-secundary), 0.8);
}

hr.order {
  margin: 20px 10px;
  border: 1px solid #393c49;
}